.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#bar-search{
  margin-bottom: 6px;
}

#bar-search input,#bar-search button, #bar-search div.MuiInputBase-root{
  background: #fff;

}
#bar-search label.MuiInputLabel-shrink{
  display: none;
  background: none;
}
#bar-search input{
  border-radius:  5px;
  height: 21px;
}

#checkboxes-categories 
{
  margin-left: 6px;
  background: #fff;
  padding-top: 9px;
  padding-bottom: 10px;
}

.selectCategories span{
  text-transform: capitalize;
}

#select-sort{
  padding-left: 0;
  padding-right: 0;
  padding: 9px;
}

#multiple-checkbox-form{
  width: 100%;
  margin: 0;
}

.selectAllCheckbox .MuiCheckbox-root
{
  display: none;
}

.selectAllCheckbox div.MuiListItemIcon-root 
{
  width: 0;
  min-width: 0;
}

div.selectAllButton{
  background-color: #0d1b36;
  color: #fff;
  display: inline;
  padding: 5px 15px;
  text-align: center;
  border-radius: 16px;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  margin: auto;
}

div.selectAllButton span{
  font-weight: bold;
}

.loader h1{
  margin-top: 0;
}

.feelLuck,.loader
{
  padding: 0 10px;
  padding-bottom: 60px;
}

.feelLuck a button, .feelLuck{
  color: #fff;
}

.feelLuck span{
  font-style: italic;
}

#InitialLoad
{
  position: absolute;
  z-index: 9;
  width: 100%;
  background-color: #0d1b36;
  height: 100%;
  padding-top: 100px;
}

#promptToInstall {
 position: fixed;
 bottom: 0;
 background-color: #0d1b36;
 width: calc(100% - 20px);
 padding: 10px;
}

#promptToInstall 
{
  border-top: 1px solid #fff;
}

#promptToInstall span {
  color: #fff;
  font-size: 1.2em;
}

#promptToInstall .button-color1 {
  color: #fff;
  background-color: #4bbd31;
  padding: 0 5px;
  width: 100%;
  min-width: 100px;
  border-radius: 6px;
  font-size: 1.5em;
}

.verticalcenter-wrapper
{
  display:table;
}
.verticalcenter
{
  display:table-cell;
  vertical-align:middle;
}
.verticalAlignCenter span{
  width: 100%;
}

#refreshIconTop{
   position: absolute;
   color: white;
   top:-50px;
   z-index: 9999;
   display: none;
  text-align: center;
  background-color: black;
  padding: 1px;
  width: 54px;
  height: 54px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 

}
#refreshIconTop svg
{
  font-size: 2em;
}

#refreshIconTop.visible{
  display: inline-block;
}