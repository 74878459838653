svg.scroll-to-top
{
    position:fixed;
    right: 15px; 
    background-color: #a6976d;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: #fff;
    -webkit-transition: bottom 0.5s;
    transition: bottom 0.5s;
}
