.logo{
 height: 40px;  
 margin-right: 5px;
}
div.MuiToolbar-root
{
    padding-left: 10px;
    padding-right: 5px;
  
}

div.MuiToolbar-root .MuiTypography-root
{
    line-height: 50px;
}

button.appInfoButton, .mobile-menu .mobile-menu-inside button
{
    font-weight: bold;
    color: #a6966d;
 
}

.mobile-menu .mobile-menu-inside {
    background-color: #0d1b36;
    color: #fff;
}

.mobile-menu .mobile-menu-inside .MuiListItemButton-gutters{
    background-color: #a6966d;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;

}


.mobile-menu .mobile-menu-inside .MuiListItemButton-gutters span{
    font-size: 1.1em;
}

.DialogBox iframe{
    width: 100%;
    min-height: calc(100vh - 300px);
}

.mobile-menu .MuiDrawer-paper, .mobile-menu .MuiBackdrop-root, .mobile-menu.MuiDrawer-root
{
    top: 56px
}

.topBar.MuiAppBar-root
{
    padding-right: 0 !important;
}

.DialogBox img{
    max-width: 100%;
}

.mobile-menu .mobile-menu-inside .MuiListItemButton-gutters.language
{
    background-color: #ffffff;
    padding: 0;

}