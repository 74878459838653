
.DealList li div span.MuiListItemText-primary
{
    font-weight: bold;
    font-size: 14px;
    height: 40px;
    overflow: hidden;
    top: -4px;
    position: relative;
}

.DealList li div p {
    font-size: 11px; 
    line-height: 1.2em;
    position: relative;
    height: 74px;
}

ul.DealList
{
    width: auto;
    padding-top: 0;
    overflow: auto;
}

ul.DealList .MuiListItemText-root{
    height: 114px;
}

li.MuiListItem-root {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
    border-left: 0;
    border-right: 0;
    max-height: 160px;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
}

.MuiListItem-root.button
{
    padding-top: 0;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}
.DealList button,.DealList a{
    align-self: flex-start;
    width: 50%;
}



.deatailImageWrapper{
    border-radius: 0px;
    opacity: 1;
    align-self: flex-start;
    min-width: 100%;
    order: 1;
    min-height: 200px;
    max-height: 200px;
    height: 200px;
    flex-grow: 1;
    width: calc(100% - 0px);
    margin: 0px;
    z-index: 3;
    overflow: hidden;
}

.detailHeader {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    background: -webkit-linear-gradient(top, rgb(224, 224, 224) 0%, rgb(182, 182, 182) 100%)
}
.detailHeader span{
    font-weight: bold;
}

.detailHeader .MuiChip-root{
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 0%, rgb(170, 170, 170) 100%);
    align-self: flex-start;
    min-width: 0px;
    order: 2;
    min-height: 30px;
    max-height: 30px;
    width: max-content;
    flex-grow: 0;
    height: max-content;
    margin: 0px;
    z-index: 1;
    overflow: visible;
    justify-content: flex-start;
    border-style: groove;
    border-width: 1px;
    border-color: rgb(107, 107, 107);
    border-radius: 10px;
    opacity: 1;
}




.detailContent
{
    padding-left: 5px;
    padding-right: 5px;
    white-space: pre-wrap;
   
}

div.MuiListItemAvatar-root {
    margin-top: 0;
}

.DealList li .MuiButtonBase-root svg
{
    font-size: 35px;
    right: -10px;
    position: absolute;
    color: #fff;
}

a.a-wrapper
{
    text-decoration: none;
    color: #fff;
}

.loader h1{
    color: #fff;
}

.DealList button.button-how{
    width: auto;
    text-decoration: underline;
    padding: 0;
    bottom: -10px;
    position: absolute;
    left: 0;
}

.codePromo
{
    background-color: #fff;
    border: 4px dashed;
    font-size: 2em;
    font-weight: bold;
    border-radius: 15px;
    padding: 10px 50px;
}

.codePromo.copied
{
    background-color: #c5f0b0;
}


.codePromo span{
    font-size: 0.5em;
    display: block;
}

.codePromoUnder{
    font-size: 14px;
}



.bandeDore{
    color: #fff;
    text-align: center;
    display: block;
    width: 100%;
    background-color: #a6966d;
    font-weight: bold;
    height: 24px;
    line-height: 24px;;
}


#detailDialog span{
    color: #0d1b36;
}

  
@media only screen and (max-width: 450px) {
    .DialogBox .DialogBoxContent  h2{
        padding: 10px 0;
        font-size: 17px;
    }

  }
  
@media only screen and (max-width: 400px) {
    .codePromoUnder{
        font-size: 12px;
    }
    .DialogBox .DialogBoxContent  h2{
        padding: 10px 0;
        font-size: 16px;
    }

  }
  li.highlight{
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  li.highlight,li.highlight p ,.DealList li.highlight  button,
  .DealList li.highlight .MuiButtonBase-root svg{
    background-color: #a6966d;
    color: #0d1b36;
  }
  li.highlight .bandeDore{
    color: #fff;
    background-color: #0d1b36;
  }

  .DealList li.highlight .MuiButtonBase-root svg {
    right: 0;
  }

  #detailDialog a{
    text-decoration: none;
  }

  .codePromo{
    color: #0d1b36;
    border-color: #0d1b36;
  }