body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.MuiToolbar-root .MuiTypography-root,
.DealList button,
.Mui-selected,ul.DealList, ul.DealList p {
  color: #fff ;
}

button.Mui-selected, div.MuiToolbar-root,ul.DealList , body{
  background-color: #0a1b36;
}



.borderNoBottom{
  border: 1px solid;
  border-bottom: 0;
}
.borderNoTop{
  border: 1px solid;
  border-top: 0;
}

