body{
    white-space: pre-line;
}
button.button-color1{
    background-color: #a6966d;
    color: #0d1b36;
    font-weight: bold;
    min-width: 200px;
    height: 60px;
    font-size: 1.7em;
    border-radius: 15px;
    text-transform: none;
    padding: 0 20px;
}

button.button-color1:hover
{
    background-color: #a6966d;
}

.titleColor1{
    color: #0d1b36;
}

button.button-small{
    padding: 0 10px;
    min-width: 40px;
    height: auto;
    border-radius: 5px;
    font-size: 1.2em;
}

#detailDialog span.bandeDore2 , .DialogBox span.bandeDore2 
{
    background-color: #a6966d;
    color: #fff;
    width: calc(100% + 28px);
    display: block;
    font-size: 26px;
    padding: 10px 0;
    left: -14px;
    position: relative;
}


.DialogBox{
    text-align: center;
}
.DialogBox>div>div{
    margin: 10px;
    border-radius: 24px;
}

.DialogBox h2{
    padding: 10px 14px;
    text-transform: uppercase;
    margin: 0;
    font-weight: bold;

}

.DialogBox h2.MuiDialogTitle-root
{
    margin-right: 20px;
}
.DialogBox h2 span{
    text-transform: none;
}
.DialogBox .DialogBoxContent  h2{
    padding: 10px 0;
    font-size: 19px;
}


.DialogBox .MuiDialogContent-root{
    border-radius: 24px;
    padding: 10px 14px;
}

.verticalAlignCenter{
    display: flex;
    align-items: center;
}
